import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import CommonParentSection from './CommonParentSection';
import * as homePageActions from '../../../actions/homePageActions';
import HomePageVehicledetailsCard from './HomePageVehicledetailsCard';
import { HomePageVehicleLoader } from '../../../common_components/Loader/Index';
import HomeUpcomingVehicleCard from './HomeUpcomingVehicleCard';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';


export default function LatestVehicles({ title, type, subType, data }) {
  const dispatch = useDispatch();
  const { getFilteredVehicles } = bindActionCreators(homePageActions, dispatch);
  const { latestVehicles, vehicleLoading } = useSelector(
    (state) => state.home
  );

  const [fetchLoading, setFetchLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  // const [vehicleLoading, setVehicleLoading] = useState(true);
  const [count, setCount] = useState(4);
  const [Limit, setLimit] = useState(10);
  const [current, setcurrent] = useState(7);
  const [slicesize, setSliceSize] = useState(8);

  // useEffect(() => {
  //   if (!fetchLoading) {
  //     getFilteredVehicles({ order_by: 4, limit: Limit }).then((res) => {
  //       setVehicles(res.data.result.docs);
  //       setVehicleLoading(false);
  //     });
  //     setFetchLoading(true);
  //   }
  // }, [fetchLoading, getFilteredVehicles, Limit]);

  useEffect(() => {
    if (count % 7 === 0 && count < 14) {
      if (count >= current) {
        setSliceSize(slicesize + 8);
        setLimit(Limit + 6)
        setFetchLoading(false)
        setcurrent(count);
      }
      else {
        setSliceSize(slicesize - 8)
        setLimit(Limit - 6)
        if (count === 7) {
          setcurrent(count)
        }
      }
    }
  }, [count])

  const HandleScroll = (direction) => {
    var container = document.getElementById(`latest-${subType === 1 ? 'scooter' : 'bike'}`)
    if (direction === 'left') {
      container.scrollLeft -= 265;
      if (count > 4) {
        // console.log(count - 1)
        setCount(count - 1);
      }
    }
    else {
      container.scrollLeft += 265;
      if (count > 3) {
        setCount(count + 1);
        // console.log(count + 1)
      }
    }
  }


  const renderLatestVehicles = useMemo(
    () =>
      data?.docs.length > 0 ?  data?.docs?.slice(0, slicesize).map((vehicle) => (
          <div className='d-inline-block me-3'>
            <HomePageVehicledetailsCard
              vehicleData={vehicle}
              label={'Latest'}
              subtype = {title}
            />
          </div>
        ))
        : null,
    [latestVehicles, vehicleLoading]
  );
  return (
    <Fragment>
      <CommonParentSection title={title} type={type} subType={subType}>
        {data?.docs?.length > 0 ? (
          <div className='position-relative scroll-container-hide' >
            <div
              className='d-flex justify-content-start align-items-center flex-row scroll-container scroll-container-full'
              id={`latest-${subType === 1 ? 'scooter' : 'bike'}`}
              style={{ height: "410px" }}
            >
              {renderLatestVehicles}
            </div>
            <button
              className='scroll-btn scroll-btn-left scroll-btn-position d-none d-xl-block'
              onClick={() => HandleScroll("left")}
              // style={{ top: "11rem" }}
              aria-label="Scroll to latest vehicles on the left"
            >
              <AiOutlineLeft id ={`latest-${subType === 1 ? 'scooter' : 'bike'}-left`} className='Right-left-icon' />
            </button>
            <button
              className='scroll-btn scroll-btn-right scroll-btn-right-position d-none d-xl-block'
              onClick={() => HandleScroll("right")}
              // style={{ top: "11rem" }}
              aria-label="Scroll to latest vehicles on the right"
            >
              <AiOutlineRight  id={`latest-${subType === 1 ? 'scooter' : 'bike'}-right`} className='Right-left-icon ' />
            </button>
          </div>
        ) : <HomePageVehicleLoader />}

      </CommonParentSection>

    </Fragment>
  );
}

